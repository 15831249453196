<template>
  <div>
    <b-row>
      <b-col md="12">
        <filters
          @filtersUpdated="filtersChangeHandler"
        />
      </b-col>
    </b-row>
    <sales-man-list :filters="filters" />
  </div>
</template>

<script>
import Filters from '@/components/MonthlyTargets/Filters/Filters.vue'
import SalesManList from '@/components/MonthlyTargets/SalesManList.vue'

import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    Filters,
    SalesManList,
  },
  data() {
    return {
      filtersInitialized: false,
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
      if (this.filtersInitialized === false) {
        this.filtersInitialized = true
      }
    },
  },
}
</script>
