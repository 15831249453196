<template>
  <div>
    <!-- Loading Spinner -->
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <!-- Error Alert -->
    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>{{ loadingError }}</p>
      </div>
    </b-alert>

    <b-modal
      v-model="modalShow"
      size="lg"
      scrollable
      title="Salesman Monthly Detail"
      @hidden="modalShow = false"
    >
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>

      <div v-if="!loading && !loadingError">
        <b-col md="6">
          <b-form-group label="Date Range">
            <div class="monthly-target">
              <flat-picker
                v-model="dateRange"
                placeholder="Select Date Range"
                :config="flatpickrConfig"
              />
            </div>
          </b-form-group>
        </b-col>

        <b-table-simple responsive>
          <b-tbody>
            <!-- Row for Name -->
            <b-tr>
              <b-th>Name</b-th>
              <b-td>{{ salesmanDetail && salesmanDetail.name }}</b-td>
            </b-tr>

            <!-- Row for Target -->
            <b-tr>
              <b-th>Target</b-th>
              <b-td>{{ salesmanDetail && salesmanDetail.monthly_target }}</b-td>
            </b-tr>

            <!-- Row for Achieved Target -->
            <b-tr>
              <b-th>Achieved Target</b-th>
              <b-td>{{ totalTarget }}</b-td>
            </b-tr>
            <b-tr>
              <b-th />
              <b-td>
                <b-progress
                  :value="totalTarget"
                  :max="salesmanDetail.monthly_target"
                  :variant="getProgressVariant(totalTarget, salesmanDetail.monthly_target)"
                  show-progress
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            variant="secondary"
            @click="modalShow = false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Table -->
    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template
        #cell(progress)="data"
        class="m-5"
      >
        <b-progress
          :value="data.item.achieved_target"
          :max="data.item.monthly_target"
          :variant="getProgressVariant(data.item.achieved_target, data.item.monthly_target)"
          show-progress
        />
      </template>
      <template #cell(action)="data">
        <feather-icon
          v-b-tooltip.hover="{ boundary: 'window' }"
          :style="{ margin: 'auto' }"
          title="View Achieved Targets"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="onTargetChange(data.item)"
        />
      </template>
    </b-table>

    <!-- Pagination -->
    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => { currentPage = page }"
    />
  </div>
</template>

<script>
import {
  BSpinner,
  BTable,
  BAlert,
  VBTooltip,
  BButton,
  BTableSimple,
  BModal,
  BCol,
  BFormGroup,
  BTr,
  BTbody,
  BTh,
  BTd,
  BProgress,
} from 'bootstrap-vue'
import axios from 'axios'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import 'flatpickr/dist/flatpickr.css'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    DetailedPagination,
    BButton,
    BTableSimple,
    BModal,
    FlatPicker,
    BCol,
    BFormGroup,
    BTr,
    BTbody,
    BTh,
    BTd,
    BProgress,
  },
  mixins: [dateMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      modalShow: false,
      salesmanDetail: {},
      dateRange: '',
      flatpickrConfig: {
        dateFormat: 'm/Y', // Format for binding (MM/YYYY)
        altInput: true, // Show formatted date in input
        altFormat: 'F Y', // Readable format (e.g., September 2024)
        plugins: [new MonthSelectPlugin({ shorthand: true, dateFormat: 'm/Y', altFormat: 'F Y' })],
      },
      totalTarget: 0,
      showAddForm: false,
      currentPage: 1,
      perPage: 50,
      sortBy: 'received_date',
      results: [],
      totalItems: 0,
      sortDesc: true,
      tableFields: [
        {
          key: 'id', label: 'ID', sortable: true, thAttr: { style: 'width: 100px' },
        },
        {
          key: 'name', label: 'Name', sortable: true, thAttr: { style: 'width: 200px' },
        },
        {
          key: 'monthly_target', label: 'Monthly-Target', sortable: true, thAttr: { style: 'width: 200px' },
        },
        {
          key: 'achieved_target', label: 'Achived-Target', sortable: true, thAttr: { style: 'width: 200px' },
        },
        {
          key: 'progress', label: 'Progress', sortable: true, tdAttr: { style: 'padding:0 50px 0 0' },
        },
        {
          key: 'action', label: 'Action', sortable: true, thAttr: { style: 'width: 50px' },
        },
      ],
      openItemId: null,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    dateRange() {
      if (this.modalShow) {
        this.fetchTargets()
      }
    },
    modalShow() {
      if (this.modalShow) {
        this.dateRange = `${new Date().getMonth() + 1}/${new Date().getFullYear()}`
      } else {
        this.salesmanDetail = {}
        this.totalTarget = 0
        this.dateRange = ''
      }
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else if (this.filters.salesmans !== undefined || this.filters.month !== undefined) {
        this.fetchItems({ salesman: [...this.filters.salesmans], month: this.filters.month })
      } else {
        this.fetchItems([])
      }
    },
  },
  created() {
    this.fetchItems([])
  },
  methods: {
    fetchItems(filterObj) {
      this.loading = true
      axios
        .get(`/accounts/salesmans_targets${filterObj?.month?.split('/')[0] ? `?month=${filterObj?.month?.split('/')[0]}` : ''}${filterObj?.month?.split('/')[1] ? `&year=${filterObj?.month?.split('/')[1]}` : ''}`)
        .then(res => {
          if (filterObj.salesman === undefined || filterObj?.salesman?.length === 0) {
            this.results = res.data.sort((a, b) => {
              if (a.id === 1) return -1 // If `a` has id: 1, place it first
              if (b.id === 1) return 1 // If `b` has id: 1, place it first
              return a.id - b.id // Otherwise, sort in ascending order
            }) || [] // Fallback to an empty array if data is undefined
          } else {
            this.results = res.data?.filter(salesman1 => filterObj.salesman.includes(salesman1.id)).sort((a, b) => {
              if (a.id === 1) return -1 // If `a` has id: 1, place it first
              if (b.id === 1) return 1 // If `b` has id: 1, place it first
              return a.id - b.id // Otherwise, sort in ascending order
            }) || []
          }
          this.totalItems = this.results.length
          this.loadingError = null // Reset loading error
          this.loading = false // Set loading to false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    onTargetChange(item) {
      this.modalShow = true
      this.salesmanDetail = { ...item }
    },
    getProgressVariant(achieved, target) {
      const progress = Math.min((achieved / target) * 100, 100)
      if (progress < 20) return 'danger'
      if (progress < 80) return 'warning'
      return 'success'
    },
    async fetchTargets() {
      if (this.dateRange.length !== 0) {
        try {
          const j = await axios.get(`/accounts/salesman/total/?salesman=${this.salesmanDetail.name}&month=${this.dateRange.split('/').join('-')}`)
          this.totalTarget = j.data.total_amount
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Total fetched',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          this.totalTarget = 0
          const res = JSON.parse(error.request.response)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.totalTarget = 0
      }
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
  },
}
</script>
