<template>
  <b-card no-body>
    <b-card-header class="align-items-start">
      <h4>Sales Man</h4>
      <div class="flex-grow-1 ml-1">
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-alert
        variant="danger"
        :show="loadingError ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ loadingError }}
          </p>
        </div>
      </b-alert>
      <template v-if="!loadingError">
        <b-row
          v-if="filterMode === null"
          class="align-items-center"
        />
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Salesman"
              label-for="salesman"
            >
              <custom-v-select
                id="salesman"
                v-model="filtersData.salesmans"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="salesmanOptions"
                label="text"
                placeholder="Select Salesman"
                multiple
                :close-on-select="false"
                :deselect-from-dropdown="true"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Date Range"
              label-for="month"
            >
              <flat-picker
                id="month"
                v-model="filtersData.month"
                placeholder="Select Date Range"
                :config="flatpickrConfig"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BCard, BSpinner, BCardHeader, BCardBody, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isEqual } from 'lodash'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import 'flatpickr/dist/flatpickr.css'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BCard,
    CustomVSelect,
    BSpinner,
    BCardHeader,
    BCardBody,
    BAlert,
    FlatPicker,
  },
  data() {
    return {
      salesmanOptions: [],
      filters: [],
      filter: null,
      filtersData: {
        salesmans: [],
        month: `${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
      },
      flatpickrConfig: {
        dateFormat: 'm/Y', // Format for binding (MM/YYYY)
        altInput: true, // Show formatted date in input
        altFormat: 'F Y', // Readable format (e.g., September 2024)
        plugins: [new MonthSelectPlugin({ shorthand: true, dateFormat: 'm/Y', altFormat: 'F Y' })],
      },
      filterName: null,
      defaultFilter: false,
      filterMode: null,
      deleteFilter: null,
      savingFilter: false,
      loading: true,
      loadingError: null,
      oldOutputFiltersData: {},
      initialized: false,
    }
  },
  computed: {
    outputFiltersData() {
      const filtersData = {}
      if (this.filtersData.salesmans.length > 0 || this.filtersData.month) {
        filtersData.salesmans = this.filtersData.salesmans
        filtersData.month = this.filtersData.month
      }
      return filtersData
    },
  },
  watch: {
    outputFiltersData: {
      deep: true,
      handler() {
        if (!this.initialized) {
          return
        }
        if (isEqual(this.oldOutputFiltersData, this.outputFiltersData)) {
          return
        }
        this.emitOutputFiltersData()
      },
    },
  },
  async created() {
    this.initializeFilters()
  },
  methods: {
    async initializeFilters() {
      await this.fetchOptions()
      if (this.loadingError) {
        return
      }

      await this.refreshFilters(true)
      if (this.loadingError) {
        return
      }

      this.emitOutputFiltersData()
      this.initialized = true
    },
    async fetchOptions() {
      this.loading = true
      let res
      try {
        res = await axios.get('/accounts/salesmans_options')
        this.salesmanOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Error fetching filter options'
      }
      this.loading = false
    },
    async refreshFilters(selectDefaultFilter) {
      this.loading = true
      const oldSelectedFilter = this.filter

      let res
      try {
        res = await axios.get('/accounts/saved_filter/')
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Error fetching filters'
        this.loading = false
        return
      }

      this.filters = res.data
      this.filter = null
      if (selectDefaultFilter) {
        const filter = this.filters.find(savedFilter => savedFilter.default === true)
        if (filter) {
          this.filter = filter
        }
      } else if (oldSelectedFilter) {
        const filter = this.filters.find(savedFilter => savedFilter.id === oldSelectedFilter.id)
        if (filter) {
          this.filter = filter
        }
      }
      this.setFiltersData()
      this.loading = false
    },
    editFilter() {
      this.filterName = this.filter.name
      this.defaultFilter = this.filter.default
      this.filterMode = 'edit'
    },
    addFilter() {
      this.filterName = null
      this.defaultFilter = false
      this.filterMode = 'add'
    },
    async submitFilter() {
      const isFormValid = await this.$refs.filterForm.validate()
      if (!isFormValid) {
        return
      }

      this.savingFilter = true
      let req
      let successMessage
      let errorMessage
      if (this.filterMode === 'add') {
        req = axios.post('/accounts/saved_filter/', {
          name: this.filterName,
          filters: this.filtersData,
          default: this.defaultFilter,
        })
        successMessage = 'Filter created successfully'
        errorMessage = 'Error creating filter'
      } else {
        req = axios.patch(`/accounts/saved_filter/${this.filter.id}/`, {
          name: this.filterName,
          filters: this.filtersData,
          default: this.defaultFilter,
        })
        successMessage = 'Filter updated successfully'
        errorMessage = 'Error updating filter'
      }

      req.then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: successMessage,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.filterMode = null
        this.refreshFilters(false)
        this.savingFilter = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.error || errorMessage,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.savingFilter = false
      })
    },
    setFiltersData() {
      if (!this.filter) {
        this.filtersData = {
          salesmans: [],
          month: '',
        }
      } else {
        this.filtersData = {
          salesmans: this.filter.filters.salesmans || [],
          month: this.filter.filters.month || '',
        }
      }
    },
    emitOutputFiltersData() {
      this.oldOutputFiltersData = this.outputFiltersData
      this.$emit('filtersUpdated', this.outputFiltersData)
    },
  },
}
</script>
